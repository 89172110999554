<template>
  <section id='contact' style='width: 100vw; display: table-row;'>
    <div class='contact'>
      <p class='content-header'><b>Contact</b></p>
      <p class='subcontent-header'>Feel free to reach us over at:</p>
      <p>admin@sway.pw<br>(973) 298-0649
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
.subcontent-header {
  margin: 0 0 5px 0;
  font-size: 1.25rem;
}
.contact {
  padding: 1.25vh 0 2.5vh 0;
}
</style>
