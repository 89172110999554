<template>
  <section id='divider' style='width: 100vw; display: table-row;'>
    <div class='divider' />
  </section>
</template>

<script>
export default {
  name: 'Divider'
}
</script>

<style scoped>
.divider {
  padding: 1px 0 1px 0;
  background-image: radial-gradient(circle, #2c3e50, rgba(255,255,255,0));
}
</style>