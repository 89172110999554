<template>
  <div class='container'>
    <Navbar />
    <Intro />
    <Divider />
    <About />
    <Divider><a id='services'></a></Divider>
    <Services />
    <Divider><a id='contact'></a></Divider>
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Divider from './components/Divider.vue'
import Intro from './components/Intro.vue'
import About from './components/About.vue'
import Services from './components/Services.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Divider,
    Intro,
    About,
    Services,
    Contact,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.container {
  display: table;
  position: relative;
}
.content-header {
  font-size: 2rem;
  margin-bottom: 5px;
}
a:link, a:visited {
  color: white;
  text-decoration: none;
  display: inline-block;
}
a:hover, a:active {
  color: #94c4ff;
}
</style>
