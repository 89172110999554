<template>
  <section id='navbar' style='width: 100vw; display: table-row;'>
    <div class="interactive">
      <div class="navbar" :class="{ 'navbar--hidden': !showNavbar }">
        <!--<div class='div-intro-logo'><img src='../assets/logo2.png' class='intro-logo'></div>-->
        <div class='links'>
          <p><a href='#about'><b>About Us</b></a></p>
        </div>
        <div class='links'>
          <p><a href='#services'><b>Services</b></a></p>
        </div>
        <div class='links'>
          <p><a href='#contact'><b>Contact</b></a></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Navbar',

  data () {
    return {
      showNavbar: true,
      lastScrollPosition: 0
    }
  },

  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 30) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    }
  }
}
</script>

<style scoped>
p {
  color: white;
  font-size: 1.2rem;
}
.div-intro-logo {
  margin: 10px 20px 0 10px;
  flex: 0 0 auto;
}
.intro-logo {
  width: 50px;
  height: 50px;
}
.links {
  transform: translate(0, -10%);
  max-height: 50px;
}
a:link, a:visited {
  padding: 10px 20px 10px 20px;
  color: white;
  text-decoration: none;
  display: inline-block;
}
a:hover, a:active {
  padding: 10px 20px 10px 20px;
  background-color: hsl(230, 3%, 43%);
}

@media (max-width: 714px) {
  p {
    font-size: 0.9rem;
  }
  .links {
    transform: translate(0, 2.5%);
  }
  .div-intro-logo {
    margin: 10px 10px 0 10px;
  }
}

/* navbar */
/* https://medium.com/@Taha_Shashtari/hide-navbar-on-scroll-down-in-vue-fb85acbdddfe */
* {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
}
.interactive {
  width: 100vw;
  background: hsl(200, 50%, 90%);
  position: absolute;
  z-index: 9;
}
.navbar {
  height: 70px;
  width: 100vw;
  background: hsl(230, 3%, 22%);
  position: fixed;
  box-shadow: 0 2px 15px rgba(138, 140, 147, 0.5);
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: top;
}
.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
</style>