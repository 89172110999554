<template>
  <section id='services' style='width: 100vw; display: table-row;'>
    <div class='services'>
      <p class='content-header'><b>Services</b></p>
      <div class='card-grid'>
        <div class='card'>
          <h3>Technical Assistance</h3>
          <p>From file recovery, to late night network repair,
            <br>We have the ability to get your computer back in top shape in no time.
          </p>
        </div>
        <div class='card'>
          <h3>PC Tune-Ups</h3>
          <p>Got a virus?
            <br>Maybe your PC is just running a little slow? We can get your PC running like new again.
          </p>
        </div>
        <div class='card'>
          <h3>Custom Hardware</h3>
          <p>Need something specific?
            <br>From high-end to as cheap as possible, we can build it or advise as to what best suits your needs.
          </p>
        </div>
        <div class='card'>
          <h3>Office Installations</h3>
          <p>Have a new building?
            <br>Need a network live or computers at every desk?
            <br>We've got your covered with the latest technology.
          </p>
        </div>
        <div class='card'>
          <h3>Hosting and Servers</h3>
          <p>Have a server you need taken care of?
            <br>Need somewhere to host your website?
            <br>We offer support contracts and hosting.
          </p>
        </div>
        <div class='card'>
          <h3>Something else?</h3>
          <p>Anything else you may need, let us know and we will either provide what you need or point you in the right direction.
          </p>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Services'
}
</script>

<style scoped>
h1 {
  color: white;
}
.services {
  padding: 1.25vh 0 2.5vh 0;
  background-image: radial-gradient(circle, #fafafa, white);
}
.card {
  background: white;
  min-width: 275px;
  max-width: 275px;
  min-height: 150px;
  flex: 1 1 auto;
  border: 3px solid white;
  /*border-radius: 5px;*/
  box-shadow: 3px 3px 2px #dbdbdb;
  margin: 10px 20px 10px 20px;
  padding: 10px 25px 10px 25px;
}
.card-grid {
  display: flex;
  width: 70%;
  flex-flow: row wrap;
  justify-items: center;
  justify-content: center;
  margin: auto;
}

@media (max-width: 714px) {
  .card-grid {
    width: 100%;
  }
  .card {
    min-width: 65%;
    min-height: 65%;
    margin: 20px 0 20px 0;
  }
}
</style>
