<template>
  <section id='footer' style='width: 100vw; display: table-row;'>
    <div class='footer'>
      <p><b>© Big Beard Technologies</b>
        <br><a href='mailto:admin@sway.pw'>admin@sway.pw</a>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
p {
  color: white;
}
.footer {
  padding: 1.25vh 2.5% 1.25vh 2.5%;
  background: #333333;
  text-align: left;
}

@media (max-width: 714px) {
  .footer {
    padding: 1.25vh 0 1.25vh 0;
    text-align: center;
  }
}
</style>
