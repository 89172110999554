<template>
  <header>
    <div class="introduction">
      <div class='div-intro-logo'><img src='../assets/logo2.png' class='intro-logo'></div>
      <br>
      <div class="intro-content">
        <p class='title'><b>Big Beard Technologies</b></p>
        <p class='subtitle'>We can solve just about every tech related problem you may have.<br>With our experience in 
both personal and business solutions<br> your problems will simply disappear.</p>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Intro'
};
</script>

<style scoped>
.title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 0.1rem;
}
.subtitle {
  color: white;
  font-size: 1.75rem;
}
.div-intro-logo {
  display: inline-block;
  margin: 15vh 0 0 0;
}
.intro-logo {
  width: 35vh;
  height: 35vh;
  opacity: 0;
}
.intro-content {
  display: inline-block;
  margin: 5vh 0 0 0;
}
.introduction {
  height: 80vh;
  width: 100vw;
  display: table-cell;
  background-blend-mode: multiply;
  background-color: #666666;
  background-image: url("../assets/header-img.png");
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

@media (max-width: 714px) {
  .title {
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: 1rem;
  }
}
</style>
