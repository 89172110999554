<template>
  <section id='about' style='width: 100vw; display: table-row;'>
    <a id='aboutus' />
    <div class='about'>
      <div class='header-column'>
        <p class='content-header'><b>About Us</b></p>
        <!--<img src="../assets/sway.jpg" width='200' height='200' class='head-image'>-->
      </div>
      <div class='info-column'>
        <h3>Let's cut to the chase</h3>
        <p>We're a small team of specialists based mostly in Northern New Jersey.
          <br>All of us have worked in a wide spectrum of tech based jobs. Some of the notable ones include;
          <br><i>Linux Systems Admin</i>, <i>Network Administrator</i>, <i>Network Architect</i> and <i>Windows Admin</i>.
          <br><br>We pride ourselves in our ability to work in so many different areas of tech, and all of our employees strive 
          <br>to learn more and expand their knowledge all the time.<br><br>
        </p>

        <h3>We care about our customers</h3>
        <p>We promise to do our best at solving any tech related issue you might have, quickly and effectivly.
          <br>Our customers are important to us.
          <br>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped>
.about {
  padding: 1.25vh 0 2.5vh 0;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
}
.head-image {
  margin: 10px 0 0 0;
}

.header-column {
  padding: 0 2% 0 5%;
  float: left;
  flex: 1 1 auto;
  text-align: right;
}
.info-column {
  padding: 1.75rem 10% 0 2%;
  float: right;
  flex: 1 3 auto;
  text-align: left;
  max-width: 60vw;
}

@media (max-width: 714px) {
  .header-column {
    padding: 0 2% 0 2%;
    text-align: center;
  }
  .info-column {
    padding: 1.75rem 2% 0 2%;
    text-align: center;
    max-width: 90vw;
  }
}
</style>
